import { Navigate, useRoutes } from "react-router-dom";
// view
import RegistrationAuth from "./view/authentication/registration";
import JoinWatlist from "./view/authentication/joinwaitlist";
import CodeVerify from "./view/authentication/codeverify";
import LoginAuthentication from "./view/authentication/login";
import ForgetPassword from "./view/authentication/forgetPassword";
import ResetPassword from "./view/authentication/resetPassword";
import UserIdentification from "./view/authentication/userIdentification";
import AddOutCome from "./view/outcome";
import AddImprovement from "./view/improvements";
import AddInfluences from "./view/influence";
import ReflectiveQuestion from "./view/reflective-questions";
import TrackingView from "./view/tracker";
import TrackingConfirmation from "./view/trackingConfirmation";
import TrackingViewNewV2 from "./view/trackerNewV2";
import Version from "./view/History-version";
import SettingsComp from "./view/settings";
import ProfileComp from "./view/profile";
import FAQComp from "./view/faq";
import Progress from "./view/progress";
import ProgressWeek from "./view/progressWeek";
import InsightsView from "./view/insights";
import GoalsView from "./view/goals";
import LearnMoreView from "./view/learn-more";
import GetCoachView from "./view/coach";
import AddCategoryIndex from "./view/category/AddCategoryIndex";
import TestGoals from "./view/testAddGolas";
import QuestionPage from "./view/testAddGolas/questionPage/QuestionPage";
import { Provider as InsightTypeProvider } from "./context/userInsights";
import { Provider as ReportsTypeContextProvider } from "./context/userIReports";
import { Provider as ContactsProvider } from "./context/userIContacts";
import ArchiveView from "./view/archives";
import AddTrackingPopUp from "./view/testAddGolas/AddTrackingPopUp/AddTrackingPopUp";
import ShareViewReport from "./view/ShareReportView";
import SocialReports from "./SocialReports";

export default function Router() {
  // CHECK IS TOKEN IS AVAIABLE OR NOT
  const checkValidToken = () => {
    return localStorage.getItem("userDetails");
  };
  return useRoutes([
    {
      path: "/",
      element: checkValidToken() ? (
        <Navigate to="/tracking" />
      ) : (
        <LoginAuthentication />
      ),
    },
    {
      path: "login",
      element: checkValidToken() ? (
        <Navigate to="/tracking" />
      ) : (
        <LoginAuthentication />
      ),
    },
    { path: "register", element: <RegistrationAuth /> },
    { path: "join-waitlist", element: <JoinWatlist /> },
    {
      path: "report-view/uid/:uid/shareReportId/:shareReportId",
      element: (
        <ReportsTypeContextProvider>
          <ShareViewReport />
        </ReportsTypeContextProvider>
      ),
    },
    // { path: "verifycode", element: <CodeVerify /> },
    {
      path: "user-identification",
      element: checkValidToken() ? (
        <UserIdentification />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "outcome",
      element: checkValidToken() ? <AddOutCome /> : <Navigate to="/login" />,
    },
    {
      path: "forget-password",
      element: checkValidToken() ? (
        <Navigate to="/tracking" />
      ) : (
        <ForgetPassword />
      ),
    },
    {
      path: "reset-password",
      element: checkValidToken() ? (
        <Navigate to="/tracking" />
      ) : (
        <ResetPassword />
      ),
    },
    {
      path: "plan-create",
      element: checkValidToken() ? (
        <AddImprovement />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "influence",
      element: checkValidToken() ? <AddInfluences /> : <Navigate to="/login" />,
    },
    {
      path: "reflective-questions",
      element: checkValidToken() ? (
        <ReflectiveQuestion />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "tracking-old",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <TrackingView />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "tracking",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <TrackingViewNewV2 />
          {/* <TrackingView /> */}
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "tracking/confirmation",
      element: checkValidToken() ? (
        <>
          <TrackingConfirmation />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "archive",
      element: checkValidToken() ? <ArchiveView /> : <Navigate to="/login" />,
    },
    {
      path: "progress",
      element: checkValidToken() ? <Progress /> : <Navigate to="/login" />,
    },
    {
      path: "progress/weekly",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <ProgressWeek />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "version",
      element: checkValidToken() ? <Version /> : <Navigate to="/login" />,
    },
    {
      path: "settings",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <SettingsComp />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "profile",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <ProfileComp />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "faq",
      element: checkValidToken() ? <FAQComp /> : <Navigate to="/login" />,
    },
    {
      path: "insights",

      element: checkValidToken() ? (
        <InsightTypeProvider>
          <SocialReports />
          <InsightsView />
        </InsightTypeProvider>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "insights?:insightsID",
      element: checkValidToken() ? (
        <InsightTypeProvider>
          <InsightsView />
        </InsightTypeProvider>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "goals",
      element: checkValidToken() ? <GoalsView /> : <Navigate to="/login" />,
    },
    {
      path: "learn-more",
      element: checkValidToken() ? <LearnMoreView /> : <Navigate to="/login" />,
    },
    {
      path: "coach",
      element: checkValidToken() ? <GetCoachView /> : <Navigate to="/login" />,
    },
    {
      path: "add-category",
      element: checkValidToken() ? (
        <>
          <SocialReports />
          <AddCategoryIndex />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "test-goals",
      element: checkValidToken() ? (
        <TestGoals />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "question-page",
      element: checkValidToken() ? (
        <QuestionPage />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "AddTrackingPopUp",
      element: checkValidToken() ? (
        <AddTrackingPopUp />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
  ]);
}
