import React from "react";

const Star = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17513 1.20359C5.57234 0.624014 6.42766 0.624014 6.82487 1.20359L7.915 2.79421C8.04503 2.98394 8.23649 3.12305 8.45712 3.18809L10.3068 3.73333C10.9807 3.932 11.245 4.74546 10.8166 5.30233L9.64065 6.83063C9.50039 7.01293 9.42726 7.238 9.43358 7.46793L9.4866 9.39553C9.50591 10.0979 8.81394 10.6006 8.15193 10.3652L6.33505 9.71914C6.11833 9.64208 5.88167 9.64208 5.66495 9.71914L3.84807 10.3652C3.18606 10.6006 2.49409 10.0979 2.5134 9.39553L2.56642 7.46793C2.57274 7.238 2.49961 7.01293 2.35935 6.83063L1.18344 5.30233C0.754978 4.74546 1.01929 3.932 1.69324 3.73333L3.54288 3.18809C3.76351 3.12305 3.95497 2.98394 4.085 2.79421L5.17513 1.20359Z"
        fill="#FFEF5A"
      />
    </svg>
  );
};

export default Star;
