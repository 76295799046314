import * as React from "react";

const SuccessIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99967 16.6666C13.6816 16.6666 16.6663 13.6819 16.6663 9.99998C16.6663 6.31808 13.6816 3.33331 9.99967 3.33331C6.31778 3.33331 3.33301 6.31808 3.33301 9.99998C3.33301 13.6819 6.31778 16.6666 9.99967 16.6666Z" stroke="#11734A" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.0655 8L8.89545 12.17L7 10.2745" stroke="#11734A" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};
export default SuccessIcon;
